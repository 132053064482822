<template>
  <v-row class="mt--30">
    <!-- Start Single Portfolio  -->
    <v-col
      lg="3"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a href="itemm.href">
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <h4 class="heading-title">
                {{ item.title }}
              </h4>
            </div>
            <div class="portfolio_hover">
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <router-link
          class="transparent_link"
          :to="item.to"
        ></router-link>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        portfolioContent: [
          {
            src: require("../../assets/images/main_th01.png"),
            category: "Development",
            title: "Overview",
            to: "/Overview",
            description:
              "(주)데이터데이터엔지니어스랩의 회사개요 입니다.",
          },
          {
            src: require("../../assets/images/main_th04.png"),
            category: "Application",
            title: "Business",
            to: "/Business",
            description:
              "사업영역을 안내 드립니다.",
          },
          {
            src: require("../../assets/images/main_th03.png"),
            category: "Application",
            title: "History",
            to: "/History",
            description:
              "2007년부터 걸어온 길을 안내합니다.",
          },
          {
            src: require("../../assets/images/main_th02.png"),
            category: "Product Design",
            title: "Contact",
            to: "Contact",
            description:
              "연락처와 위치를 안내합니다.",
          },
        ],
      };
    },
  };
</script>
